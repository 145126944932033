import React from "react";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { useIntl } from "gatsby-plugin-intl";
import { graphql, useStaticQuery } from "gatsby";

import SEO from "../components/seo";
import Text from "../components/text";
import Title from "../components/title";
import Layout from "../layout/site-layout";
import Return from "../components/return-button";
import Introduction from "../components/introduction";

const StreetArtInstallation = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      street1: file(name: { eq: "zollikerstrasse_2" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      street2: file(name: { eq: "zollikerstrasse_3" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      street3: file(name: { eq: "zollikerstrasse_6_Tag" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      street4: file(name: { eq: "zollikerstrasse" }) {
        name
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <Layout>
        <SEO title="Street Art Installation" />
        <Title text="Shining Stones Street Art Installation" />
        <Introduction>
          {intl.formatMessage({ id: "street.intro_1" })}
        </Introduction>
        <Introduction>
          {intl.formatMessage({ id: "street.intro_2" })}
        </Introduction>
        <Text>
          {intl.formatMessage({ id: "street.text_1" })}
          <a href="/shining-stones">Shining Stones</a>
          {intl.formatMessage({ id: "street.text_2" })}
        </Text>
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, 1fr);
            grid-gap: 20px;
            margin-top: 20px;
          `}
        >
          <Img
            fluid={data.street1.childImageSharp.fluid}
            key={data.street1.name}
            alt={data.street1.name}
          />
          <Img
            fluid={data.street2.childImageSharp.fluid}
            key={data.street2.name}
            alt={data.street2.name}
          />
          <Img
            fluid={data.street3.childImageSharp.fluid}
            key={data.street3.name}
            alt={data.street3.name}
          />
          <Img
            fluid={data.street4.childImageSharp.fluid}
            key={data.street4.name}
            alt={data.street4.name}
          />
        </div>
        <Return />
      </Layout>
    </>
  );
};

export default StreetArtInstallation;
